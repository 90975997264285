.block-content {
    display: flex;
    border: 1px solid;
    border-radius: 10px;
    padding: 10px;
    margin: 15px 0;
    /* background-color: rgb(221, 221, 221); */
    /* background-color: rgb(221, 209, 253); */
    background-color: white;
}

.right-content {
    padding: 10px;
    width: auto;
}

/*
    For Experiences blocks
*/

.block-content .img-div {
    height: 130px;
    width: 130px;
}

.block-content img {
    height: 130px;
    width: 130px;
    border-radius: 10px;
}

.position {
   margin: 0 10px;
   color: rgb(0, 110, 255);
}

.company, .technologies {
    margin: 5px 10px;
    font-style: italic;
}

.duration {
    margin: 3px 10px;
    color: rgb(138, 138, 138);
}

/*
    For Projects blocks
*/

.name {
    margin: 0 10px;
    color: rgb(0, 110, 255);
}

.block-content .project-img {
    height: 250px;
    width: auto;
    display: block;
    margin: 10px auto;
    border-radius: 10px;
}

.project-link {
    border: 1px solid;
    border-radius: 30px;
    display: block;
    width: max-content;
    padding: 5px 10px;
    margin: 10px;
    color: black;
    text-decoration: none;
}

.project-link:hover {
    background-color: rgb(222, 173, 255);
}


.block-content p {
    margin: 3px 10px;
    font-size: 15px;
}