.tabs {
  position: relative; /* Fixes the border-bottom-color overlay problem */
}

.tabs ul {
  margin-top: 25px;
  margin-bottom: 0;
}

.tabs ul li {
  width: 15%;
  padding: 10px;
  margin: 0 5px;
  border: 2px solid;
  border-radius: 10px 10px 0 0;
  border-width: 2px;
  margin-bottom: -2px;
  background-color: rgb(255, 233, 205);
  font-size: 13px;
  color: black;
}