.home-content {
  padding: 10px;
  overflow-y: auto;
}

.home-content h1 {
  font-size: 50px;
}

.home-content h2 {
  font-size: 30px;
}

.home-content p {
  font-size: 18px;
}

.home-content button {
  border: 0.5px solid rgb(58, 58, 58);
  border-radius: 5px;
  background-color: #91d5dd;
  font-size: 20px;
  font-weight: 500;
  font-family: Montserrat;
  margin-top: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.home-content button:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}
