.url-bookmark-container {
    height: 85px;
    width: auto;
    border: solid black;
    border-width: 2px 0;
    background-color: rgb(255, 233, 205);
}

.url {
    height: 25px;
    width: 38vw;
    border: 2px solid;
    border-radius: 30px;
    margin: 5px auto;
    padding: 5px 25px;
    font-size: 18px;
    background-color: white;
}