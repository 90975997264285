.contacts-bookmarks li {
    margin: 0 20px;
    padding: 5px 10px;
    border-radius: 10px;
}

.contacts-bookmarks li:hover {
    background-color: rgb(255, 210, 152);
}

.contacts-bookmarks li img {
    height: 25px;
    width: 25px;
    border-radius: 2px;
    margin: 0 5px;
    margin-bottom: -3px;
}

@media (max-width: 990px) {
    .contacts-bookmarks li {
        margin: 0 15px;
    }
}

@media (max-width: 910px) {
    .contacts-bookmarks li span {
        display: none
    }
}