.tabs ul li.active {
    /* background-color: rgb(148, 206, 233); */
    border-bottom-color: rgb(255, 233, 205);
}

.tabs ul li img {
    border-radius: 2px;
    margin-bottom: -3px;
}

#home img {
    height: 23px;
    width: 23px;
}

#experiences img {
    height: 23px;
    width: 23px;
}

#projects img {
    height: 23px;
    width: 23px;
}

#other img {
    height: 23px;
    width: 23px;
}

@media (max-width: 1200px) {
    .tabs ul li {
        width: auto !important;
    }

    .tabs ul li span {
        font-size: 10px !important;
    }
}

@media (max-width: 800px) {
    .tabs ul li {
        width: auto !important;
    }

    .tabs ul li span {
        display: none;
    }
}